<template>
  <div class="col-12 col-lg-8 c">
    <div class="card">
        <div class="card-header">
            <h4>
                <i class="fa fa-edit"></i>
                عقد العمل
            </h4>
        </div>
        <div class="card-body">
            <div class="alert alert-danger text-center g" v-if="error">{{ error }}</div>
            <div class="alert alert-success text-center g" v-if="success"><h2 class="text-success">{{ success }}</h2></div>
            <template v-if="current._id">
                <div class="row">
                    <div class="col-12 col-lg-6 g">
                        <button class="btn btn-block btn-lg btn-success" @click="print()">
                            <i class="fa fa-print"></i>
                            طباعة العقد
                        </button>
                    </div>
                    <div class="col-12 col-lg-6 g" v-b-modal.signature>
                        <button class="btn btn-block btn-lg btn-info" @click="signatureStart()" style="background: #4DA1A9 !important; border: solid 1px #4DA1A9 !important">
                            <i class="fa fa-pencil"></i>
                            توقيع العقد
                        </button>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <b-modal id="signature" title="توقيع العقد" hide-footer>
        <canvas id="theCanvas" style="width: 100%; height: 100px; border: solid 1px darkred"></canvas>
        <div class="col-12 text-center">
            <button id="clearButton" class="btn btn-sm btn-outline-danger" style="margin: 5px">
                <i class="fa fa-times"></i>
                ازالة التوقيع</button>
            <button class="btn btn-success" @click="saveSignature()" style="margin: 5px">
                <i class="fa fa-check"></i>
                حفظ التوقيع</button>
        </div>
        <hr>
        <img :src="require('@/assets/images/signature.gif')" style="width: 100%">
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            current: {},
            success: null,
            error: null
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/web/contract', {
            jwt: g.user.jwt
        }).then(function(r){
            if(r.status == 100){
                g.current = r.response;
            }else{
                g.error = r.response;
            }
        })
    },
    methods: {
        saveSignature(){
            var g = this;
            var content = document.getElementById("theCanvas").toDataURL();
            $.post(api + '/admin/web/save-signature', {
                jwt: g.user.jwt,
                content: content
            }).then(function(r){
                $("#signature___BV_modal_header_ > button").click()
                if(r.status == 100){
                    g.current.signature = content
                    g.error = null;
                    g.success = r.response
                }else{
                    g.success = null;
                    g.error = r.response;
                }
            })
        },
        signatureStart(){
            var g = this;
            setTimeout(function(){
                const MAIN_MOUSE_BUTTON = 0;

                function prepareContext(canvasElement) {
                let dpr = window.devicePixelRatio || 1;
                let rect = canvasElement.getBoundingClientRect();
                canvasElement.width = rect.width * dpr;
                canvasElement.height = rect.height * dpr;
                
                let context = canvasElement.getContext("2d");
                context.scale(dpr, dpr);
                
                return context;
                }

                function setLineProperties(context) {
                context.lineWidth = 2;
                context.lineJoin = "round";
                context.lineCap = "round";
                return context;
                }

                let clearButton = document.getElementById("clearButton");
                let theCanvas = document.getElementById("theCanvas");
                let theContext = prepareContext(theCanvas);
                let shouldDraw = false;

                theCanvas.addEventListener("mousedown", start);
                theCanvas.addEventListener("mouseup", end);
                theCanvas.addEventListener("mousemove", move, false);

                theCanvas.addEventListener("touchstart", start);
                theCanvas.addEventListener("touchend", end);
                theCanvas.addEventListener("touchcancel", end);
                theCanvas.addEventListener("touchmove", move, false);

                clearButton.addEventListener("click", event => {
                    clearCanvas(theContext);
                });

                
                function clearCanvas(context) {
                context.clearRect(0, 0, context.canvas.width, context.canvas.height);  
                }

                function start(event) {
                    shouldDraw = true;
                    setLineProperties(theContext);

                    theContext.beginPath();
                    
                    let elementRect = event.target.getBoundingClientRect();
                    theContext.moveTo(event.clientX - elementRect.left, event.clientY - elementRect.top);
                }

                function end(event) {
                    shouldDraw = false;
                }

                function move(event) {
                if (shouldDraw) {
                    let elementRect = event.target.getBoundingClientRect();
                    theContext.lineTo(event.clientX - elementRect.left, event.clientY - elementRect.top);
                    theContext.stroke()
                }
                }
            }, 100)
        },
        print(){
            var g = this;
            var win = window.open("");
            g.header = JSON.parse(g.current.info).contract.header;
            g.body = JSON.parse(g.current.info).contract.body;
            g.footer = JSON.parse(g.current.info).contract.footer;
            g.current.content = JSON.parse(g.current.content);
            win.document.write(`
            <html dir='rtl'>
            <head>
                <style>
                @page {
                    margin: 0;
                }
                h4{
                    line-height: 1.8;
                }
                body{
                    background-image: url("/images/contract.png") ;
                    background-repeat:repeat-y;
                    background-position: right top;
                    background-attachment:fixed;
                    background-size:100%;
                }
                .content{
                    padding: 60px;
                    padding-top: 130px;
                    padding-bottom: 100px;}
                    .page-break {
                page-break-before: always; /* Forces a new page with padding */
                padding-top: 130px; /* Ensures the top padding is repeated on new pages */
            }
                </style>
            </head>
            <body>
                <div class='content'>
                <center><h2>عقد عمل موسمي لحج عام ١٤٤٦ هـ</h2></center>
                <h4>${g.replaceVariables(g.current, g.header)}</h4>
                <h4>السيد/ ${g.current.name} 
                &nbsp;&nbsp;
                &nbsp;&nbsp;
                &nbsp;&nbsp;
                &nbsp;&nbsp;
                الجنسية/ ${g.current.content.national}</h4>
                <h4 style='padding: 5px; border-top: 0.5px solid #ddd; border-bottom: 0.5px solid #ddd;'>
                    بموجب هوية رقم/ ${g.current.content.number}
                    &nbsp;&nbsp;
                    &nbsp;&nbsp;
                    &nbsp;&nbsp;
                    صادرة من/ ${g.current.content.number_source}
                    &nbsp;&nbsp;
                    &nbsp;&nbsp;
                    &nbsp;&nbsp;
                    وتاريخ الميلاد/ ${g.current.content.birth_date.replaceAll('-', '/')}
                </h4>
                <h4 style='padding: 5px; padding-top: 0px; margin-top: -10px; border-bottom: 0.5px solid #ddd;'>
                    رقم الجوال/ 
                    &nbsp;&nbsp;
                    &nbsp;&nbsp;${g.current.phone}
                </h4>
                <h4>${g.replaceVariables(g.current, g.body)}</h4>
                <div class='page-break'>
                    <h4>
                    ${g.replaceVariables(g.current, g.footer)}
                    </h4>
                    <div style='display: inline-block; width: 49%'>
                    <h4 style='margin: 0px auto; text-align: center'>الطرف الأول</h4>
                    <h4 style='margin: 0px auto; text-align: center'>عمر عبدالعزيز عبدالله الشلاش</h4>
                    <h4>التوقيع:</h4>
                    </div>
                    <div style='display: inline-block; width: 49%'>
                    <h4 style='margin: 0px auto; text-align: center'>الطرف الثاني</h4>
                    <h4 style='margin: 0px auto; text-align: center'>${g.current.name}</h4>
                    <h4>&nbsp;&nbsp;&nbsp;التوقيع: ${g.current.signature ? "<img width='250' src='"+g.current.signature+"'>" : ''}</h4>
                    </div>
                </div>
                </div>
            </body>
            </html>
            `)
            win.document.close()
            setTimeout(() => {
            win.print()
            }, 1000);
        },
        replaceVariables(current, input){
            for(var i=0; i<=20; i++){
            input = input.replace( /{(.*?)}/, function (x) {
            x = x.replace('{', '').replace('}', '')
            if(x == 'job'){
                if(current.job['code']){
                    return current.job['title'];
                }
            }
            if(current[x]){
                return current[x];
            }
            if(current.info[x]){
                return current.info[x];
            }
            if(current.content[x]){
                return current.content[x];
            }
            if(current.job[x]){
                return current.job[x];
            }
            return `{${x}}`;
            } )
            }
            return input
        },
    }
}
</script>

<style>

</style>